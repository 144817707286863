import React from 'react'
import './Portfolio.css'

const Portfolio = () => {
  const data = [



    {
      id: 1,
      image: "assests/img1.png",
      title: 'Carheo',
      gitub: 'https://github.com',
      demo: 'https://dribbble.com/shots/18603080-MyStudio-Dashboard-Studio-Management'
    },
    {
      id: 2,
      image: "assests/img8.jpg",
      title: 'Pkversity',
      gitub: 'https://github.com',
      demo: 'https://dribbble.com/shots/18603080-MyStudio-Dashboard-Studio-Management'
    },
    {
      id: 3,
      image: "assests/portfolio5.jpg",
      title: 'Programmers',
      gitub: 'https://github.com',
      demo: 'https://dribbble.com/shots/18603080-MyStudio-Dashboard-Studio-Management'
    }
    // {
    //   id: 4,
    //   image: "assests/img7.png",
    //   title: 'University Panel ',
    //   gitub: 'https://github.com',
    //   demo: 'https://dribbble.com/shots/18603080-MyStudio-Dashboard-Studio-Management'
    // },
    // {
    //   id: 5,
    //   image: "assests/img5.png",
    //   title: 'Pkversity',
    //   gitub: 'https://github.com',
    //   demo: 'https://dribbble.com/shots/18603080-MyStudio-Dashboard-Studio-Management'
    // },
    // {
    //   id: 6,
    //   image: "assests/img6.png",
    //   title: 'Admin Panel',
    //   gitub: 'https://github.com',
    //   demo: 'https://dribbble.com/shots/18603080-MyStudio-Dashboard-Studio-Management'
    // }
  ]
  return (
    <section id="portfolio" className='justify-content-middle1 '>
<div className="container-fluid">
  <div className="row  pb-5">
    <div className="col-md-12">
    <h2 className='text-center pb-5'>Portfolio</h2>
    </div>
  </div>
  <div className="row">
        {
          data.map((item) => {
            return (
              <div className="col-lg-4 col-xs-12 mb-4" key={item.id}>
                <article className='card shadow-sm border-0'>
                  
                    <div className="card-body">
                      <img src={item.image} alt="" className='image-fluid'/>
                    </div>
                    <h4 className="text-center">{item.title}</h4>
                    <div className="porifolio_item-cta">
                      {/* <a href={item.gitub} className='btn' target='_blank' rel="noreferrer">Gitub</a> */}
                      {/* <a href='/' className='btn btn-primary' target='_blank' rel="noreferrer">View Site</a> */}
                  
                  </div>
                </article>
              </div>

            )
          })

        }
      </div>
</div>
      

     
    </section>
  )
}

export default Portfolio
