import React from 'react'
import { Link } from 'react-router-dom';
import './Nav.css'


const Nav = () => {
  return (
    <>
    <section>
    <nav class="navbar navbar-expand-lg fixed-top bg-light shadow-sm">
        <div class="container-fluid">
        <Link class="navbar-brand" to="/">
                <img src="assests/cyberoide.png" alt='' height="50"/>
            </Link>
    <button class="navbar-toggler text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span><i class="bi bi-border-width h6"></i></span>
    </button>
          <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 ">
              <li class="nav-item "> 
        <Link className='nav-link'  to="/" role="button">Home</Link>

              </li>
              <li class="nav-item">
                <a class="nav-link" href="/#services">Servies</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/#portfolio">Portfolio</a>
              
              </li>
              <li class="nav-item">
                {/* <a class="nav-link" href="/#">Contact</a> */}
        <Link className='nav-link'  to="/contact" role="button">Contact</Link>
              </li>

            </ul>
           
          </div>
        </div>
      </nav>
</section>
   
 
</>
  )
}

export default Nav