
const ServicesApi=[
    {
        id:1,
        logo:"https://img.icons8.com/color/512/google-code.png",
        title:"Application Development ",
        description:`Our highly skilled developers design and develop professional and technologically advanced custom applications. We provide a range of solutions for designing, developing and maintaining applications of any complexity.`
    },
    {
        id:2,
        logo:"https://img.icons8.com/external-flat-juicy-fish/512/external-dev-coding-and-development-flat-flat-juicy-fish.png",
        title:"DevSecOps",
        description:`Our DevSecOps team specializes in building next-gen CI/CD pipelines for zero downtime deployment. Our architects work with your team to design secure cloud-based architecture.`
    },
    {
        id:3,
        logo:"https://img.icons8.com/external-sapphire-kerismaker/512/external-cyber-cyber-security-color-sapphire-kerismaker-10.png",
        title:"Application Security Testing ",
        description:`Our security experts detect vulnerabilities in your code to enhance the security of your applications. We conduct comprehensive evaluations and recommend the optimal security solutions.`
    },
    {
        id:4,
        logo:"https://img.icons8.com/external-flaticons-flat-flat-icons/512/external-quality-assurance-ux-and-ui-flaticons-flat-flat-icons.png",
        title:"Software Quality Assurance ",
        description:`We offer thorough bug and error detection, robust software quality assurance, and technical solutions to meet the needs of software testing, bug fixing and produce reliable and safe software.`
    },
    {
        id:5,
        title:"Content Writing",
        logo:"https://img.icons8.com/external-smashingstocks-circular-smashing-stocks/512/external-content-writing-education-smashingstocks-circular-smashing-stocks.png",
        description:`We offer specialized technical and market-relevant content globally. Our team of experts assist clients in research and delivering fact-based content by understanding and meeting their needs.`
           
    
    },
    {
        id:6,
        title:"Technical Recruitment",
        logo:"https://img.icons8.com/external-kosonicon-flat-kosonicon/512/external-recruitment-business-teamwork-kosonicon-flat-kosonicon.png",
        description:"We provide assistance in streamlining the process of technical staff recruitment to explore and find best suited talent by remote and on demand services.",
    },
]
export default ServicesApi;
