import React, { useState } from 'react'
import ServicesApi from './../../Api/ServicesApi'
import './Services.css'


const Services = () => {
  const [serviceData] = useState(ServicesApi);


  return (
    <>

      <section id='services' class="box1">

        <div class="container-fluid">
          <div className="row pt-5 pb-5">
            <div class="col-md-12">
              <h1 class="heading text-center mt-4 mb-5">
                Services
              </h1>
            </div>
          </div>
          <div class="row">
            
            {
              serviceData.map((curElem) => {
                const { id, logo, title, description } = curElem;
                return (
                  <div class="col-lg-4 col-xs-12 text-center mb-5" key={id}>
                    <div class="card  shadow-sm border-0">
                      <div className="card-body box card-height">
                        {/* <i class={logo} aria-hidden="true"></i> */}
                        <img src={logo} alt="" className='img-thumbnail1' />
                        <div class="box-title">
                          <i class="fa-solid fa-grill-hot"></i>
                          <h3>{title}</h3>
                        </div>
                        <div class="box-text">
                          <span>{description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }


          </div>
        </div>


      </section>

    </>
  )
}

export default Services
