import React, { useState } from 'react'
import Nav from './components/Navbar/Nav'
import Header from './components/Header/Header';
import Services from './components/Services/Services';
// import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import Testimonial from './components/Testimonial/Testimonial';
import Portfolio from './components/Portfolio/Portfolio';
import { useEffect } from 'react';
import ReactGA from "react-ga"
import {AiOutlineArrowUp} from 'react-icons/ai'



 
const Home = () => {
  const[fun,useFun]=useState(
    document.body.scrollTop = 0, // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  );
 
  useEffect(()=>{
ReactGA.pageview(window.location.pathname);
 
  },[])
  return (
    <>
    <button onClick={useFun} id="myBtn" title="Go to top"><AiOutlineArrowUp/></button>
    <Nav/>
    <Header/>
    <Services/>
  <Testimonial/>
    <Portfolio/>
    {/* <ContactUs/> */}
    <Footer/>
</>    
  )
}

export default Home;