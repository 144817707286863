import React from 'react'
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';
import {BsGithub} from 'react-icons/bs'
import Nav from "../Navbar/Nav";
import { BsInstagram } from 'react-icons/bs';
import './AboutUs.css'
const AboutUs = () => {
  return (
<>
<Nav/>
<div class="container py-5 mt-5">
    <div class="row text-center text-black">
        <div class="col-lg-8 mx-auto">
            <h1 class="display-4">Our Team</h1>
        </div>
    </div>
</div>


<div class="container ">
    <div class="row text-center">

        
    <div class="col-xl-3 col-sm-6 mb-5">
            <div class="bg-white rounded shadow-sm py-5 px-4" style={{height:'100%'}}>
                <img src="assests/arslana.jpeg" alt=""  class="img-fluid rounded-circle mb-3 img-thumbnail "/>
                <h5 class="mb-0">Arslan Akhtar</h5><span class="small text-uppercase text-muted">CEO</span>
                <ul class="social mb-0 list-inline mt-3">
                <li class="list-inline-item"><a href="https://twitter.com/MuhammadawabAh2" class="social-link"><AiOutlineTwitter className='icon'/></a></li>
                    <li class="list-inline-item"><a href="https://www.linkedin.com/in/muhammad-awab-ahmed-394aa0221/" class="social-link"><AiFillLinkedin className='icon'/></a></li>
                    <li class="list-inline-item"><a href="https://www.facebook.com/muhammad.awab.338" class="social-link"><BsGithub className='icon'/></a></li>
                    <li class="list-inline-item"><a href="https://www.instagram.com/awab4850/" class="social-link"><BsInstagram className='icon'/></a></li>
                </ul>
            </div>
        </div>

        
        <div class="col-xl-3 col-sm-6 mb-5">
            <div class="bg-white rounded shadow-sm py-5 px-4" style={{height:'100%'}}><img src="assests/f.jpg" alt=""  class="img-fluid rounded-circle mb-3 img-thumbnail "/>
                <h5 class="mb-0">Malik Faizan</h5><span class="small text-uppercase text-muted">Business Developer</span>
                <ul class="social mb-0 list-inline mt-3">
                <li class="list-inline-item"><a href="/#" class="social-link"><AiOutlineTwitter className='icon'/></a></li>
                    <li class="list-inline-item"><a href="/#" class="social-link"><AiFillLinkedin className='icon'/></a></li>
                    <li class="list-inline-item"><a href="/#" class="social-link"><BsGithub className='icon'/></a></li>
                    <li class="list-inline-item"><a href="/#" class="social-link"><BsInstagram className='icon'/></a></li>
                </ul>
            </div>
        </div>

        
        <div class="col-xl-3 col-sm-6 mb-5">
            <div class="bg-white rounded shadow-sm py-5 px-4" style={{height:'100%'}}><img src="assests/Awab.jpeg" alt=""  class="img-fluid rounded-circle mb-3 img-thumbnail "/>
                <h5 class="mb-0">M. Awab</h5><span class="small text-uppercase text-muted">Creative Expert</span>
                <ul class="social mb-0 list-inline mt-3">
                <li class="list-inline-item"><a href="https://twitter.com/MuhammadawabAh2"  class="social-link" target="_blank" rel="noopener noreferrer"><AiOutlineTwitter className='icon'/></a></li>
                    <li class="list-inline-item"><a href="https://www.linkedin.com/in/muhammad-awab-ahmed-394aa0221/" class="social-link" target="_blank" rel="noopener noreferrer"><AiFillLinkedin className='icon'/></a></li>
                    <li class="list-inline-item"><a href="https://github.com/Muhammad-Awab" class="social-link" target="_blank" rel="noopener noreferrer"><BsGithub className='icon'/></a></li>
                    <li class="list-inline-item"><a href="https://www.instagram.com/awab4850/" class="social-link" target="_blank" rel="noopener noreferrer"><BsInstagram className='icon'/></a></li>
                </ul>
            </div>
        </div>

        
        <div class="col-xl-3 col-sm-6 mb-5">
            <div class="bg-white rounded shadow-sm py-5 px-4" style={{height:'100%'}}><img src="assests/rehan.jpg" alt=""  class="img-fluid rounded-circle mb-3 img-thumbnail "/>
                <h5 class="mb-0">Rehan Awan</h5><span class="small text-uppercase text-muted">Technical Support Specialist</span>
                <ul class="social mb-0 list-inline mt-3">
                <li class="list-inline-item"><a href="/#" class="social-link"><AiOutlineTwitter className='icon'/></a></li>
                    <li class="list-inline-item"><a href="/#" class="social-link"><AiFillLinkedin className='icon'/></a></li>
                    <li class="list-inline-item"><a href="/#" class="social-link"><BsGithub className='icon'/></a></li>
                    <li class="list-inline-item"><a href="/#" class="social-link"><BsInstagram className='icon'/></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
</>



  )
}

export default AboutUs