import React from 'react'
import './Header.css'

import { Link } from 'react-router-dom';

import Typical from 'react-typical'

const steps = [
// 'DevSecOps Engineer ', 3000,
// 'SQA Engineer ', 3000,
// 'Content Writer', 4000,
// 'Web Developer', 3000,
// 'Application Developer', 3000,
// 'Technical Recruiter ', 3000,
'Lets Build Your Digital Journey ' ,3000,
'Cyberoide: The Key to Your Peace of Mind',3000
];

const Header = () => {
return (
<section class="bg-image box1 justify-content-middle"> 

<div class="container-fluid p-5" >

  <div class="row ">
    <div className="col-md-6 ">
      <h1 class="display-1 height-cyberoide fw-bold">
        Cyberoide
      </h1>
      <p class="name">Don't Be the Next Headline</p>
   <h1 class="mt-5">
    
   <span className='display-5'>
          <Typical wrapper="span" steps={steps} loop={Infinity} className={'caca'} />
            </span>
   </h1>
        
        <p className='lead pt-4 pb-4 hide' > <span className='text-dark fw-bold' >CYBEROIDE</span> is a cutting-edge software technology firm, has collaborated with numerous corporations and start-ups to develop exceptional products and experiences while providing best solutions to their most challenging problems.</p>
        <Link class="btn btn-contact shadow btn-lg "  to="/contact" role="button">Contact Us</Link>
    </div>
  </div>


   

</div>

</section>
)
}

export default Header
