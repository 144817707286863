import React from 'react'
import './Testimonial.css'
// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const data=[
  {
avatar:"assests/avatar4.jpg",
name:'Mikael ',
review:'Cyberoide deployed my product efficiently over Cloud infrastructure. They handled the scalability and security of the infrastructure efficiently. It s been a pleasant experience to have the DevSecOps services of Cybderoide!'

  },
  {
avatar:"assests/avatar2.jpg",
name:'Anthony',
review:'I and Cyberioide have been working since the inception of my eCommerce startup. Their product development skills are exceptional. They designed the mockups and managed all aspects of software development as a technical partner. We are about to live soon.'
  },
  {
avatar:"assests/avatar3.jpg",
name:'Tahir',
review:'I am using their inventory management system at almost 5 stores. All features are accessible without any complexity.I am genuinely excited to have more projects with them!'
  }
]
const Testimonials = () => {
  return (
    <div>
   
    <section id="testimonials" className='testimonial1 pt-5 pb-5'>
 
      <div className="container-fluid">
      <div className="row">
          <div className="col-md-12 text-center">
          <h2 className='text-center'>Testimonials</h2>
          </div>
        </div>
        <div className="row">

          <div className="col-md-12">
        
      <Swiper  modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}>
       {
        data.map((item,index)=>{
        return(  
          <SwiperSlide key={index} className='testimonial'>
          {/* <div className="client_avatar"> */}
            {/* <img src={item.avatar} alt="Avatar One" /> */}
          {/* </div> */}
            <h3 className='client_name'>{item.name}</h3>
            <small className='client_review'>{item.review}</small>
          </SwiperSlide>
          )  })
       }
      </Swiper>
          </div>
        </div>
      </div>
    
    </section>
    </div>
  )
}

export default Testimonials
